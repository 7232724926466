import React, { Fragment } from "react";
import BaseComponent from "./../../base";
//title
import CoustTitle from "../../compontents/title_coust/title_coust";
import mcollaborateMap from "../../assets/collaborate/collaborate_11.jpg";
import mcollaborateMap1 from "../../assets/collaborate/collaborate_14.jpg";
import mcallaborateArrow from "../../assets/collaborate/arrowLeft.png";
import mcollaborateProcessImg from "../../assets/collaborate/collaborate-Wed_21.jpg";
import mcollaborateProcessImg1 from "../../assets/collaborate/collaborate-Wed_23.jpg";
import mcollaborateProcessImg2 from "../../assets/collaborate/collaborate-Wed_25.jpg";
import mcollaborateProcessImg3 from "../../assets/collaborate/collaborate-Wed_27.jpg";
import mArrowRight from "../../assets/entry/arrowRight.png";
import "./m_collaborate.css";

// 引入数据
import collData from "../../util/JSON/collaborateData.json";

class MobileCollaborate extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      statisticsInfo: collData.statisticsInfo,
      joinAdvantage: collData.joinAdvantage,
      support: collData.support,
      heights: "",
      caseCategory: "",
      city: "",
      mobile: "",
      name: "",
      titleInfo: "",
      company: "",
      adress: "",
    };
  }
  componentDidMount() {
    this.changeNav("m_collaborate");
    this.wowInit();
    let widthWindow = window.screen.width;
    this.setState({
      heights: widthWindow / 3,
    });
  }
  // 提交咨询
  SubmitForm = () => {
    let { caseCategory, city, mobile, name, company, address } = this.state;
    let phoneReg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
    if (!name) {
      this.isValue("请输入姓名");
      return;
    }
    if (!mobile) {
      this.isValue("请输入电话");
      return;
    }
    if (!phoneReg.test(mobile)) {
      this.isValue("电话号码输入不正确，请重新输入");
      return;
    }
    if (!city) {
      this.isValue("请输入城市");
      return;
    }
    if (!company) {
      this.isValue("请输入公司名称");
      return;
    }
    if (!caseCategory) {
      this.isValue("请输入案件类型");
      return;
    }
    if (!address) {
      this.isValue("请输入公司地址");
      return;
    }

    this.ajax("consult", {
      caseCategory,
      name,
      mobile,
      city,
      company,
      address,
    }).then((res) => {
      if (res.status) {
        this.isValue("客服将在五分钟内与您联系，请保持电话畅通！");
        this.setState({
          caseCategory: "",
          name: "",
          mobile: "",
          city: "",
          company: "",
          address: "",
        });
      }
    });
  };
  // 判断字段
  isValue = (string) => {
    this.setState({ titleInfo: string });
    setTimeout(() => {
      this.setState({ titleInfo: "" });
    }, 2000);
  };

  render() {
    return (
      <Fragment>
        <div className="mcollaborateBanner"></div>
        {this.collaborateStatistics()}
        {this.collaborateMap()}
        {this.collaborateMiddleImg()}
        {this.collaborateJoinAdvantage()}

        {this.collaborateSupport()}
        {this.collaborateProcess()}
        {this.formMenu()}
      </Fragment>
    );
  }
  // 司法受理数量屡创新高
  collaborateStatistics = () => {
    return (
      <div className="mcollaStatistics">
        <h2>司法案件受理数量屡创新高</h2>
        <p className="mcollaStatistics_info wow fadeInDown">
              近年来我国的司法案件受理数量屡创新高，从2010年的1106万件增长至3700万件，相当于每10个家庭就有一个家庭在打官司，每天10.1万件，每小时4155件，每秒1件
        </p>
        <ul>
          <li>
            <h3>1亿中产家庭</h3>
            <p>私人律师市场空前庞大</p>
          </li>
          <li>
            <h3>9000万中小企业</h3>
            <p>企业法律顾问需求激增</p>
          </li>
          <li>
            <h3>3000万件案件</h3>
            <p>全国民事商事案件数量激增</p>
          </li>
        </ul>
        <div className="mcollaStatisticMap">
          <div className="mmap wow fadeInDown"></div>
        </div>
      </div>
    );
  };
  // 已覆盖全国主要城市
  collaborateMap = () => {
    return (
      <div className="mcollContainerAll">
        <CoustTitle title="已覆盖全国主要城市" text="Covered City" />
        <section className="mcollContainer ">
          <img
            src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/collaborate/map.png"
            alt=""
            className="mcollaborateMap_mapImg "
          />
          <div className=" mcollaborateMap_Tagging ">
            <p className=" mcollsborate-font">
              <img className="mapImg" src={mcollaborateMap} alt="" />
              <span className="tagging-font1">律政时代的直营城市</span>
            </p>
            <p className="mcollsborate-font">
              <img className="mapCil" src={mcollaborateMap1} alt="" />
              <span className="tagging-font2">
                律政时代的业务覆盖城市及合作代理城市
              </span>
            </p>
          </div>
        </section>
      </div>
    );
  };
  // 中间大图
  collaborateMiddleImg = () => {
    return <div className="collaborateMiddleImg"></div>;
  };
  // 加盟律证时代的九大优势
  collaborateJoinAdvantage = () => {
    let { joinAdvantage } = this.state;
    return (
      <div className="mcollaborateJoinAdvantage_content_all">
        <CoustTitle title="加入律政时代的九大优势" text="Nine Advantages" />
        <ul className=" mcollaborateJoinAdvantage_content">
          {joinAdvantage.map((item, index) => {
            return (
              <li
                key={index}
                className=" mcollaborateJoinAdvantage_item wow flipInX  animated"
              >
                <div className="mcollaborateJoinAdvantage">
                  <i className={`iconfont ${item.icon}`}></i>
                  <h3>{item.title}</h3>
                </div>
                <div className="mcallaborateArrowImg">
                  <img src={mcallaborateArrow} alt="" />
                </div>
                {/* <p>{item.text}</p> */}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // 加入律师时代的六大支持
  collaborateSupport = () => {
    let { support } = this.state;
    return (
      <div className="mcollaborateSupportAll">
        <CoustTitle title="加入律师时代的六大支持" text="Six Major Support" />
        <ul className=" mcollaborateSupport_content">
          {support.map((item, index) => {
            return (
              <li key={index} className="mcollaborateSupport_item">
                <p className="supportFont">{item.title}</p>
                <p className="supportText">{item.text}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // 加盟流程
  collaborateProcess = () => {
  
    return (
      <div className="mcollaborateProcessAll">
        <CoustTitle title="合作流程"  text='Joining Process'/>
        <ul className="mcollaborate-ul">
          <li>
            <h4 className="mNumberOne">01</h4>
            <div>
              <img src={mcollaborateProcessImg} alt="" />
            </div>
            <p>在线客服或加盟热线</p>
          </li>
          <div className="marrowTip"><img src={mArrowRight} alt="" /></div>
          <li>
            <h4 className="mNumTwo">02</h4>
            <div>
              <img src={mcollaborateProcessImg1} alt="" />
            </div>
            <p>电话初步沟通</p>
          </li>
          <div className="marrowTipBottom"><img src={mArrowRight} alt="" /></div>
          <li>
            <h4 className="mNumberOne">04</h4>
            <div>
              <img src={mcollaborateProcessImg2} alt="" />
            </div>
            <p>签约合作代理</p>
          </li>
          <div className="marrowTipLeft"><img src={mArrowRight} alt="" /></div>
          <li>
            <h4 className="mNumTwo">03</h4>
            <div>
              <img src={mcollaborateProcessImg3} alt="" />
            </div>
            <p>考察洽谈</p>
          </li>
        </ul>
      </div>
    );
  };

  //  提交表单
  formMenu() {
    return (
      <div className="molawyerMenuContiner">
        <div className="molawyerMenuContinerTitle">
          <CoustTitle
            title="成功的关键在于平台要选好"
            text="Choice is important"
          />
          <div className="molawyerMenuContinerTitleRight">
            <p>专业的法律服务 全国服务热线</p>
            <p>400-777-7091</p>
          </div>
        </div>
        <div className="moformContiner">
          <div className="moformChooseContiner">
            <div>
              <p>您的姓名</p>
              <input
                type="text"
                placeholder="此处填写姓名"
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
              />
            </div>
            <div>
              <p>您的电话</p>
              <input
                type="number"
                placeholder="此处填写联系方式"
                onChange={(e) => {
                  this.setState({ mobile: e.target.value });
                }}
              />
            </div>
            <div>
              <p>所在城市</p>
              <input
                type="text"
                placeholder="请输入所在城市"
                onChange={(e) => {
                  this.setState({ city: e.target.value });
                }}
              />
            </div>
            <div>
              <p>公司名称</p>
              <input
                type="text"
                placeholder="请输入公司名称"
                onChange={(e) => {
                  this.setState({ company: e.target.value });
                }}
              />
            </div>
            <div>
              <p>案件类型</p>
              <input
                type="text"
                placeholder="请输入案件类型"
                onChange={(e) => {
                  this.setState({ caseCategory: e.target.value });
                }}
              />
            </div>
            <div>
              <p>公司地址</p>
              <input
                type="text"
                placeholder="请输入公司地址"
                onChange={(e) => {
                  this.setState({ address: e.target.value });
                }}
              />
            </div>
          </div>

          <p className="mhideText">{this.state.titleInfo}</p>
          <button className="mosubmit-btn" onClick={() => this.SubmitForm({})}>
            立即预约
          </button>
          <p className="mtipText">
            线上客服7*24小时、免费咨询法律问题，紧急的事情不能等
          </p>
        </div>
      </div>
    );
  }
}

export default MobileCollaborate;
