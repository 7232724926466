import React from "react";
import "./title_coust.css";

export default function Title(props) {
  let { title, text } = props;
  return (
    <div className="ctitle_ ">
      <p>{text}</p>
      <h3>{title}</h3>
    </div>
  );
}
