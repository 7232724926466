import React from 'react';
import './App.css';
import './animate.css'
import './iconfont/iconfont.css';

import BaseComponent from './base';
import Header from './pages/header/header';
import Footer from './pages/footer/footer';

// import MobileEntry from './mobile/mobile_entry/m_entry';
class App extends BaseComponent {
  render() {

    return (
      <div className="appContiner">
        <Header></Header>
        {this.props.children}
        <Footer></Footer>
      </div>
    );
  }
} 


export default App;
