import React from 'react';
import '../App.css'
import MobileHeader from '../compontents/mobile_header/m_header';
import MobileFooter from '../compontents/mobile_footer/m_footer';
import MobileEnter from './mobile_entry/m_entry';
import MobileCollaborate from './mobile_collaborate/m_collaborate';
class MobileApp extends React.Component {
    render() {
        return (
            <div className="appContiner">
                <MobileHeader />
                {/* <MobileEnter />
                <MobileCollaborate /> */}
                {this.props.children}
                <MobileFooter />
            </div>
        );
    }
}

export default MobileApp;