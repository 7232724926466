//律师详情
import React from "react";
import BaseComponent from "./../../../base";
import Comcerns from "../../mobile_concern/m_concern";
import { getNewDateObj } from "../../../util/date";
//css
import "./m_info.css";
// 加载中 组件
import { DotLoading } from "antd-mobile";
class MobileLawyerConsultInfo extends BaseComponent {
  state = {
    ConsultInfoId: null,
    info: {},
  };
  componentDidMount() {
    this.changeNav("m_lawyerConsult");
    this.wowInit();
    if (this.props.location && this.props.location.query) {
      sessionStorage.setItem("ConsultInfoId", this.props.location.query.id);
      this.getInfo(this.props.location.query.id);
    } else {
      let id = sessionStorage.getItem("ConsultInfoId");
      this.getInfo(id);
    }
  }
  componentWillUnMount = () => {
    // 清除请求
    this.abort();
  };
  // 获取详情
  getInfo(id) {
    if (id) {
      this.ajax("getlawyerConsultInfo", {
        id,
      }).then((res) => {
        if (res.status) {
          this.setState({
            info: res.result,
          });
        }
      });
    }
  }
  render() {
    return (
      <div className="mlawyerInfoContainer">
        <Comcerns />
        <section className="mlawyerInfoContainer_content">
          <div className="mconsultInfocontent_l">
            {this.consultInfocontent()}
          </div>
        </section>
      </div>
    );
  }
  // 文章内容
  consultInfocontent = () => {
    let { title, content } = this.state.info;
    return (
      <div>
        {title ? (
          <div className="mconsultInfocontent">
            {this.renderTimes()}
            <h1 className="mconsultInfocontent_tit">{title}</h1>
            <section className="mconsultInfocontent_c">
              <div
                className="mconsultInfocontent_content"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </section>
          </div>
        ) : (
          <div className="dotloading">
            <DotLoading color="#4b5a98" />
          </div>
        )}
      </div>
    );
  };
  // 渲染时间
  renderTimes = () => {
    let { createTime } = this.state.info;
    let obj = getNewDateObj(createTime);
    return (
      <div className="mrenderTimes">
        <p>{obj.year}</p>
        <p>
          {obj.month}/{obj.day}
        </p>
        <p>
          {obj.hour} : {obj.minutes}
        </p>
      </div>
    );
  };
}

export default MobileLawyerConsultInfo;
