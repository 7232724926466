// 找律师页面
import React from "react";
// 加载中 组件
import { DotLoading } from "antd-mobile";
import BaseComponent from "./../../base";
import SelectCity from "./mobile_selectCity/m_selectCity";
import "./m_findLawyer.css";
// 分页组件
import Pagination from "../mobile_pagination/m_pagination";
class FindLawyer extends BaseComponent {
  constructor() {
    super();
    this.state = {
      pageNo: 1,
      loading: true,
      pageSize: 10,
      total: 0, //总页数
      count: 0, //总条数
      dataList: [],
      beGoodAt: [], //擅长领域
      lawCategoryId: null, //法律Id
      isCity: false,
      // 选中
      province: {},
      city: {},
      region: {},

      search: {
        provinceId: null,
        cityId: null,
        regionId: null,
      },
      isClick: false,
    };
  }
  componentDidMount() {
    this.getLawCategories();
    this.getDateList();
    this.changeNav("m_findLawyer");
  }

  // 获取律师分类
  getLawCategories = () => {
    this.ajax("lawCategories").then((res) => {
      if (res.status) {
        res.result.unshift({ name: "不限" });
        this.setState({
          beGoodAt: res.result,
        });
      }
    });
  };
  // 获取律师列表
  getDateList = (page) => {
    // lawyers
    let { pageNo, pageSize, search, lawCategoryId } = this.state;
    if (page) {
      this.setState({ pageNo: page });
      pageNo = page;
    }
    let categoryId = null;
    if (lawCategoryId) {
      if (lawCategoryId.name !== "不限") {
        categoryId = lawCategoryId.id;
      }
    }
    let parms = Object.assign(
      {
        pageNo,
        pageSize,
        lawCategoryId: categoryId,
      },
      search
    );
    this.ajax("lawyers", parms).then((res) => {
      if (res.status) {
        this.setState({
          dataList: res.result.entities,
          total: res.result.pageAmount,
          count: res.result.total,
        });
        this.wowInit();
      }
    });
  };

  // 点击翻页
  getCurrentPage = (currentPage) => {
    this.setState(
      {
        pageNo: currentPage,
      },
      () => {
        this.getDateList();
      }
    );
  };
  // 选择省
  selctProvinceItem = (obj) => {
    this.state.search.provinceId = obj.province.code;
    this.setState(
      Object.assign(
        {
          search: this.state.search,
          isCity: false,
        },
        obj
      ),
      () => {
        this.getDateList(1);
      }
    );
  };
  // 删除面包屑
  deletehandler = (name) => {
    this.setState({
      province: name,
    });
  };
  // 选择市
  selctCityItem = (obj) => {
    this.state.search.cityId = obj.city.code;
    this.setState(
      Object.assign(
        {
          search: this.state.search,
        },
        obj
      ),
      () => {
        this.getDateList(1);
      }
    );
  };
  // 选择区
  selctRegionItem = (obj) => {
    this.state.search.regionId = obj.region.code;
    this.setState(
      Object.assign(
        {
          search: this.state.search,
        },
        obj
      ),
      () => {
        this.getDateList(1);
      }
    );
  };
  // 返回string 地区
  getStrCity = (info) => {
    let adress = "";
    if (info.provinceName && info.cityName) {
      adress = info.provinceName + "" + info.cityName;
    }
    if (info.regionName) {
      adress = adress + "" + info.regionName;
    }
    return adress;
  };
  render() {
    return (
      <div className="mfindLawyerContainer_lll">
        <section className="mfindLawyerContainer_l">
          {this.findLawyerFindFn()}
        </section>
        {this.findLawyerItem()}
      </div>
    );
  }
  // 查询条件
  findLawyerFindFn = () => {
    let { beGoodAt, isCity, province, city, region } = this.state;
    let { isClick } = this.state;
    return (
      <section className="mfindLawyerFindFn_item">
        <SelectCity
          province={province}
          city={city}
          region={region}
          selctProvinceItem={this.selctProvinceItem}
          selctCityItem={this.selctCityItem}
          selctRegionItem={this.selctRegionItem}
          deletehandler={this.deletehandler}
        />
        {this.selectfindLawyerItem(beGoodAt)}
      </section>
    );
  };
  // 擅长领域
  selectfindLawyerItem = (label) => {
    let { lawCategoryId } = this.state;
    let { isClick } = this.state;
    // let { beGoodAt } = this.state;
    lawCategoryId = lawCategoryId || {};
    return (
      <ul
        className={
          isClick
            ? "mdropDown_listat mdropDown_list_showat"
            : "mdropDown_listat"
        }
      >
        {label.map((item, index) => {
          return (
            <li
              key={index}
              // getPopupContainer={e => e.parentNode}
              onClick={() => {
                this.setState(
                  {
                    lawCategoryId: item,
                  },
                  () => {
                    this.getDateList(1);
                  }
                );
              }}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    );
  };
  // 律师
  findLawyerItem = () => {
    let { dataList } = this.state;
    return (
      <div className="mfindLawyerItem_content_all">
        <div className="mresultSortT">
          <p className="mresultSort">结果排序</p>
        </div>
        <div>
          {dataList.length ? (
            <ul className="mfindLawyerItem_content">
              {dataList.map((item, index) => {
                return (
                  <li className="mfindLawyerItem_ wow fadeInDown" key={index}>
                    <section
                      onClick={() => {
                        item.adress = this.getStrCity(item);
                        this.props.history.push({
                          pathname: "/MobileFindLawyerInfo",
                          query: {
                            info: item,
                          },
                        });
                      }}
                    >
                      <div
                        style={{
                          background: `url(${item.certPhoto}) no-repeat center`,
                        }}
                        className="mimgs"
                      ></div>
                      <div className="mfindLawyerItem_info">
                        <div className="mfindLawyerItem_info-left">
                          <h3>{item.name} &nbsp;律师</h3>
                          <p>所在地：{this.getStrCity(item)}</p>
                          <p>持证编号：{item.licenseNo}</p>
                          <div>
                            {item.skillfulMajors.length
                              ? item.skillfulMajors.map((_item, _index) => {
                                  return <span key={_index}>{_item}</span>;
                                })
                              : null}
                          </div>
                        </div>
                        <div className="mfindLawyerItem_info-right">
                          <a
                            href="http://p.qiao.baidu.com/cps/chat?siteId=13909139&userId=28915778"
                            className="mfindLawyerItem_btn"
                          >
                            线上咨询
                          </a>
                        </div>
                      </div>
                    </section>
                  </li>
                );
              })}
              <Pagination
                pageNo={this.state.pageNo}
                total={this.state.total}
                pageCallbackFn={this.getCurrentPage}
              />
            </ul>
          ) : (
            <div className="dotloading">
              <DotLoading color="#4b5a98" />
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default FindLawyer;
