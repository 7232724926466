import React from 'react';
import { observer } from 'mobx-react';
import axios from 'axios'
import { WOW } from 'wowjs';
import apis from './../api';
import RouterStore from './../store/router';

// let baseUrl = "http://api.test.lzsdtech.com/";//测试环境
let baseUrl = 'https://api.lzsdtech.com';//生产环境

let Axios = axios.create({
    baseURL: baseUrl,
    timeout: 5000
});

@observer
class BaseComponent extends React.Component {
    wowInit = () => {
        var wow = new WOW({
            live: false
        })
        wow.init()
    }
    ajax(url, params = {}, data = {}, method = 'post') {
        return new Promise((reslove, reject) => {
            Axios({
                url: apis[url],
                method,
                params,
                data,
            }).then(res => {
                reslove(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    SubmitForm(obj) {
        this.ajax('consult', obj)
        .then(res => {
            console.log(res)
        })
    }
    changeNav(path) {
        RouterStore.changeNav(path)
    }
}

export default BaseComponent;