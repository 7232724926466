import React from "react";
import BaseComponent from "./../../base/index";
// import "./m_entry.css";
import './m_entry.scss'
import { ScrollBoard } from "@jiaminghi/data-view-react";
import Title from "../../compontents/mobile_title/m_title";
function RenderTabs(tabs, tab, changeTabs) {
  return tabs.map((item, idx) => {
    if (!item.isshow) {
      return null;
    }
    return (
      <li
        key={item.index}
        className={tab === idx ? "active" : ""}
        onClick={() => {
          changeTabs(idx);
        }}
      >
        {item.name}
      </li>
    );
  });
}
function showMain(idx,data) {
  switch (idx) {
    case 0:
      return (
        <div className="firstEntryInfo">
          <div className="entyrBoard">
            <ScrollBoard
              config={{
                headerHeight: 35,      
                header: ["姓名", "性别", "年龄",'所属地区','保证金金额','退还银行及账号','公示时间','累计时间'],
                data:data,
                // headerBGC: "black",
                // oddRowBGC: "black",
                // evenRowBGC: "#ccc",
                index:true,
                waitTime: 3000,
                justifyContent:["center"],
                align: ["center"],
                columnWidth: [45],
                rowNum:[5]
              }}
              style={{ width: "100%", height: "5rem" ,fontSize:'12rem'}}
            ></ScrollBoard>
          </div>
          <p>实时更新法律最新消息，</p>
          <p>24小时为个人和企业提供高质、</p>
          <p>高效的法律服务，帮您解决法律问题</p>
        </div>
      );
      break;
    case 1:
      return (
        <div className="mentryServiceContiner">
          <ul className="mentryServiceUl">
            <li className="wow bounceInLeft">
              <div>
                <i className="iconfont icon-shengxinwuyou "></i>
                <span>随时</span>
              </div>
              <div className="mserviceHover">
                <p>线上客服</p>
                <p>7✖24小时在线</p>
              </div>
            </li>
            <li className="wow bounceInLeft">
              <div>
                <i className="iconfont icon-suishisuidi"></i>
                <span>随地</span>
              </div>
              <div className="mserviceHover">
                <p>平台覆盖全国省市,直辖区,自治区均享受服务,不限地域</p>
              </div>
            </li>
            <li className="wow fadeInDown">
              <div>
                <i className="iconfont icon-shouji"></i>
                <span>随身</span>
              </div>
              <div className="mserviceHover">
                <p>就近安排律师</p>
                <p>实现随身服务</p>
              </div>
            </li>
            <li className="wow fadeInDown">
              <div>
                <i className="iconfont icon-shengqian"></i>
                <span>省钱</span>
              </div>
              <div className="mserviceHover">
                <p>最低只要199元每年,让昂贵的法律服务惠及千家万户</p>
              </div>
            </li>
            <li className="wow bounceInRight">
              <div>
                <i className="iconfont icon-suishitixian"></i>
                <span>省时</span>
              </div>
              <div className="mserviceHover">
                <p>工厂流水化作业方式、多任务同步进行、专业人做专业事</p>
              </div>
            </li>
            <li className="wow bounceInRight">
              <div>
                <i className="iconfont icon-shengli"></i>
                <span>省力</span>
              </div>
              <div className="mserviceHover">
                <p>过程无需亲力亲为,律师主动上报平台,用户可随时查看</p>
              </div>
            </li>
          </ul>
          <div className="entryTogther">
            <h2>共享</h2>
            <p>让昂贵的法律服务惠及千家万户</p>
            <p>让中国公民及中小微企业都拥有自己的法律顾问</p>
          </div>
        </div>
      );
      break;
    case 2:
      return (
        <div className="entryProductService">
          <ul>
            <li>
              <h3>
                <span></span>线上产品服务由平台完成
              </h3>
              <p>律师函、代写文书、草拟合同、文书审查、7*24小时咨询等</p>
            </li>
            <div className="borerline"></div>
            <li>
              <h3>
                <span></span>线下产品服务由律师完成
              </h3>
              <p>免费面对面服务、案件代理、交通人伤事故等</p>
            </li>
          </ul>
          <div className="entryMode entryTogther">
            <h2>模式</h2>
            <p>互联网+法律、线上线下</p>
            <p>一家提供全方位综合性保障的一站式法律服务平台</p>
          </div>
        </div>
      );
      break;
    case 3:
      return (
        <div className="entryPracticeAreas">
          <ul>
            <li>
              <h3>婚姻家庭</h3>
              <p>离婚纠纷</p>
              <p>财产分割</p>
              <p>子女抚养</p>
            </li>
            <li>
              <h3>建筑工程</h3>
              <p>工程款纠纷</p>
              <p>工程质量纠纷</p>
              <p>劳务合同</p>
            </li>
            <li>
              <h3>合同文书</h3>
              <p>合同订立</p>
              <p>合同效力</p>
              <p>合同纠纷</p>
            </li>
            <li>
              <h3>知识产权</h3>
              <p>商标法</p>
              <p>专利法</p>
              <p>产权保护</p>
            </li>
            <li>
              <h3>企业股份</h3>
              <p>股权登记</p>
              <p>股权收购</p>
              <p>股权质押</p>
            </li>
            <li>
              <h3>企业投资融资</h3>
              <p>债权债务风险</p>
              <p>股权结构风险防范</p>
            </li>
          </ul>
          <div className="entryMode entryTogther">
            <h2>广泛</h2>
            <p>律政时代 携手共赢</p>
            <p>
              平台聚集了各类法律服务机构逾百家，大型律师事务所、品牌律师事务所十余家以及过万名专业律师
            </p>
          </div>
        </div>
      );
      break;
    default:
      break;
  }
}

class MobileEntry extends BaseComponent {
  constructor(props) {
    super(props);
    const tabs = [
      { name: "公示栏", index: 0, isshow: true },
      { name: "服务优势", index: 1, isshow: true },
      { name: "产品服务", index: 2, isshow: true },
      { name: "服务领域", index: 3, isshow: true },
    ];
    this.state = {
      tab: 0,
      tabs,
      areaList: [
        {
          title: "婚姻家庭",
          children: ["离婚纠纷", "财产分割", "子女抚养"],
        },
        {
          title: "建筑工程",
          children: ["工程款纠纷", "工程质量纠纷", "劳务合同"],
        },
        {
          title: "合同文书",
          children: ["合同订立", "合同效力", "合同纠纷"],
        },
        {
          title: "知识产权",
          children: ["商标法", "专利法", "产权保护"],
        },
        {
          title: "企业股份",
          children: ["股权登记", "股权收购", "股权质押"],
        },
        {
          title: "企业投资融资",
          children: ["企业投资融资", "债权债务风险", "股权结构风险防范"],
        },
      ],
      comparisonList: [
        {
          title: "预防",
          advantageText: [
            "律政时代融入了",
            "保险思维，为未来的",
            "法律风险未雨绸缪",
          ],
          disadvantagedText: [
            "其他的法律服务",
            "通常是等事情发生",
            "了才开始准备",
          ],
          advantageIcon: "icon-icon-test20 iconfont ",
          disadvantagedIcon: "icon-icon-test22 iconfont",
        },
        {
          title: "价格",
          advantageText: [
            "异地配合等各种方式",
            "让用户得到更优质服务的同时",
            "享受低至199元的法律服务",
          ],
          disadvantagedText: [
            "低廉的律师不敢信任",
            "高价的律师又太烧钱",
            "咨询费都几百到几千不等",
          ],
          advantageIcon: "icon-icon-test8 iconfont",
          disadvantagedIcon: "icon-icon-test12 iconfont",
        },
        {
          title: "专业",
          advantageText: [
            "业务根据客户需求",
            "推荐具有相关专长",
            "的专业律师办理",
          ],
          disadvantagedText: [
            "当律师没案源或案源不够时",
            "没有挑选的余地就会什么都接",
            "出现“万金油”现象",
          ],
          advantageIcon: "icon-icon-test21 iconfont",
          disadvantagedIcon: "icon-icon-test44 iconfont",
        },
        {
          title: "时间",
          advantageText: [
            "通过线上及线下",
            "提供多通道沟通方式让用户",
            "在第一时间得到有效服务",
          ],
          disadvantagedText: [
            "律师多案在办等等原因",
            "造成不能及时出场，",
            "贻误战机，给用户带来障碍",
          ],
          advantageIcon: "icon-icon-test9 iconfont",
          disadvantagedIcon: "icon-icon-test13 iconfont",
        },
        {
          title: "地域",
          advantageText: [
            "全方位一站式综合保障性",
            "的法律服务平台",
            "覆盖全国31个主要省市",
          ],
          disadvantagedText: [
            "本地律师只能本地办案",
            "跨地域办案，费时费力",
            "且价格昂贵",
          ],
          advantageIcon: "icon-icon-test16 iconfont",
          disadvantagedIcon: "icon-icon-test6 iconfont",
        },
        {
          title: "共享",
          advantageText: [
            "融入保险思维及共享理念",
            "全新模式打造",
            "一站式综合法律服务平台",
          ],
          disadvantagedText: ["信息不对称", "服务内容本地化", "靠谱律师少"],
          advantageIcon: "icon-icon-test5 iconfont",
          disadvantagedIcon: "icon-icon-test6 iconfont",
        },
      ],
      data: [
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
        ["行1列1", "行1列2", "行1列3","行1列1", "行1列2", "行1列3", "行1列2", "行1列3"],
      ],
    };
  }
  changeTabe = (idx) => {
    this.setState({
      tab: idx,
    });
  };
  componentWillMount() {
    this.ajax("homeSliders").then((res) => {
      // console.log(res);
    });
    this.changeNav("m_entry");
  }
  componentDidMount() {
    this.wowInit();
    let widthWindow = window.screen.width;
    // console.log(widthWindow, "window.screen.width");
    this.setState({
      heights: widthWindow / 3,
    });
  }

  render() {
    return (
      <div className="entryAll">
        <div className="entryBigImg"></div>
        {this.TabChange()}
      </div>
    );
  }

  TabChange() {
    const { tabs, tab,data} = this.state;
    return (
      <div className="tabchange-box">
        <ul>{RenderTabs(tabs, tab, this.changeTabe)}</ul>
        {showMain(tab,data)}
      </div>
    );
  }
  //服务优势
  EntryService() {
    return (
      <div className="mentryServiceContiner">
        <ul className="mentryServiceUl">
          <li className="wow bounceInLeft">
            <div>
              <i className="iconfont icon-shengxinwuyou "></i>
              <span>随时</span>
            </div>
            <div className="mserviceHover">
              <p>线上客服</p>
              <p>7✖24小时在线</p>
            </div>
          </li>
          <li className="wow bounceInLeft">
            <div>
              <i className="iconfont icon-suishisuidi"></i>
              <span>随地</span>
            </div>
            <div className="mserviceHover">
              <p>平台覆盖全国省市,直辖区,自治区均享受服务,不限地域</p>
            </div>
          </li>
          <li className="wow fadeInDown">
            <div>
              <i className="iconfont icon-shouji"></i>
              <span>随身</span>
            </div>
            <div className="mserviceHover">
              <p>就近安排律师</p>
              <p>实现随身服务</p>
            </div>
          </li>
          <li className="wow fadeInDown">
            <div>
              <i className="iconfont icon-shengqian"></i>
              <span>省钱</span>
            </div>
            <div className="mserviceHover">
              <p>最低只要199元每年,让昂贵的法律服务惠及千家万户</p>
            </div>
          </li>
          <li className="wow bounceInRight">
            <div>
              <i className="iconfont icon-suishitixian"></i>
              <span>省时</span>
            </div>
            <div className="mserviceHover">
              <p>工厂流水化作业方式、多任务同步进行、专业人做专业事</p>
            </div>
          </li>
          <li className="wow bounceInRight">
            <div>
              <i className="iconfont icon-shengli"></i>
              <span>省力</span>
            </div>
            <div className="mserviceHover">
              <p>过程无需亲力亲为,律师主动上报平台,用户可随时查看</p>
            </div>
          </li>
        </ul>
      </div>
    );
  }
  changeMobil(e) {
    console.log(e);
  }
  //产品服务
  EntryProductService() {
    return (
      <div className="mentryProductService">
        <Title title="产品服务" text="PRODUCT SERVICE" />
        <div className="mentryService">
          <div className="mtitlePrice">
            <span className="mcompanyService">企业专享服务</span>
            <span className="mentryProductPrice">低至1888元</span>
          </div>
          <div className="lineBox"></div>
          <p className="mentryServiceEnTitle">ENTERPRISE EXCLUSIVE SERVICE</p>
          <p className="miconfont ">线上法律服务</p>
          <div className="mentry_lineHeight">
            <p>电话咨询（7*24小时全年无休）、</p>
            <p>文书下载（7*24小时会员尊享）、</p>
            <p>专属律师（即时配备）、代写文书非诉讼类（答辩状、起诉状）</p>
            <p>
              代写文书非诉讼类（法律意见书、草拟合同、公司章程、员工手册）、
            </p>
            <p>律师函、文书审查（2小时内完成）</p>
          </div>
          <p className="miconfont">线下法律服务</p>
          <div className="mentry_lineHeight">
            <p>重大案情会诊、公司会议、商务谈判、</p>
            <p>法律培训风险评估、商务调查、</p>
            <p>司法案件委托：≤30万主诉案件无偿代理、</p>
            <p>≤50万被诉案件无偿代理、权益内无限制</p>
          </div>
        </div>
      </div>
    );
  }

  //服务内容
  EntryServiceContent() {
    return (
      <div className="mentryServiceArea">
        <Title title="服务领域" text="SERVICE AREA" />
        <ul className="mentryAreaUl">{this.EntryArea()}</ul>
      </div>
    );
  }
  //服务内容ul
  EntryArea() {
    return this.state.areaList.map((value) => {
      return (
        <li key={value.title} className="mentryAreaLi">
          <div className="mareaTitleContiner">
            <img
              src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/entry/area_back.png"
              alt=""
            />
            <p>{value.title}</p>
          </div>
          <ul>
            <li>
              {value.children.map((item) => {
                return <p key={item}>{item}</p>;
              })}
            </li>
          </ul>
        </li>
      );
    });
  }
  // 传统律师行业   律政时代平台
  EntryServicwComparison() {
    let comparisonList = this.state.comparisonList;
    return (
      <div className="mentryComparison">
        <Title title="传统律师行业  律政时代平台" text="PLATFORM COMPARISON" />
        <ul className="mentryServiceContinerUl wow fadeInDown">
          {comparisonList.map((value) => {
            return (
              <li key={value.title}>
                <div className="mImgFont">
                  <img
                    src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/entry/comparison.png"
                    className="mcomparisonImg"
                    alt=""
                  />
                  <p className="mcomparisonTitle">{value.title}</p>
                </div>
                <div className="mcomparisonContiner">
                  <i className={value.advantageIcon}></i>
                  {value.advantageText.map((item) => {
                    return (
                      <p key={item} className="mcomparisonText">
                        {item}
                      </p>
                    );
                  })}
                </div>

                <div className="mdiscomparisonContiner">
                  <span className="mcomparisonVs">vs</span>
                  <i className={value.disadvantagedIcon}></i>
                  <div className="mdisContinerFont">
                    {value.disadvantagedText.map((item) => {
                      return (
                        <p key={item} className="mcomparisonText">
                          {item}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default MobileEntry;
