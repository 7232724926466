// 转换时间搓
export function getNewDate(time) {
    if (!time) {
        return;
    }
    let newDate = new Date(time);
    let getYear = newDate.getFullYear();
    let getMonth = newDate.getMonth() + 1;
    let getDay = newDate.getDate();
    return getYear + '-' + getMonth + '-' + getDay;
}
// 获取时间对象
export function getNewDateObj(time) {
    if (!time) {
        return {};
    }
    let newDate = new Date(time);
    let getYear = newDate.getFullYear();
    let getMonth = newDate.getMonth() + 1;
    let getDay = newDate.getDate();
    let gethour = newDate.getHours();
    let Minutes = newDate.getMinutes()
    
    if (getMonth < 10) {
        getMonth = '0' + getMonth
    }
    if (getDay < 10) {
        getDay = '0' + getDay
    }
    if (gethour < 10) {
        gethour = '0' + gethour
    }
    if (Minutes < 10) {
        Minutes = '0' + Minutes
    }
    return {
        year: getYear,
        month: getMonth,
        day: getDay,
        hour: gethour,
        minutes: Minutes
    }
}