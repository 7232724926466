import React, { Component } from "react";
import "./m_title.css";

class Title extends Component {
    render() {
        let { title, text } = this.props;
        return (
            <div className="mtitle_ ">
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        )
    }
}
export default Title