import React, { Fragment } from "react";
import { observer } from "mobx-react";
import "./header.css";
import BaseComponent from "../../base";
import RouterStore from "../../store/router";
import logo from '../../assets/entry/entry_04.jpg'
import { Link } from "react-router-dom";

@observer
class Header extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      navList: [
        {
          name: "首页",
          path: "entry",
        },
        {
          name: "私人律师服务",
          path: "coustomerLawyer",
        },
        {
          name: "企业法律顾问",
          path: "companyLawyer",
        },
        {
          name: "合作代理",
          path: "collaborate",
        },
        {
          name: "找律师",
          path: "findLawyer",
        },
        {
          name: "经典案例",
          path: "lawyerConsult",
        },
        
        {
          name: "关于我们",
          path: "aboutUs",
        },
      ], //导航栏数据
      curNav: RouterStore.curNav,
    };
  }
  navList() {
    return (
      <div className="headerNavContiner">
        {/* 头部左侧图片 */}
        <div className="headerNavContiner-img">
            <img src={logo} alt="" />
        </div>
        {/* 头部导航栏 */}
        <ul className="headerNavUl">
          {this.state.navList.map((value) => {
            return (
              <li
                key={value.path}
                onClick={() => this.navgatorTo(value.path)}
                className={
                  RouterStore.curNav === value.path
                    ? "curNavLi headerNavLi"
                    : "headerNavLi"
                }
              >
                <Link style={{ textDecoration:'none'}} className="headerLink" to={`/${value.path}`}>
                  {value.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div >
        {/* <div className="headerSignContiner">
                    <p className="headerWelcomeText">欢迎访问律政时代官方网站为你提供：私人律师服务，企业法律顾问，共享律师，让昂贵的法律惠及千家万户。</p>
                </div> */}
        {/* <div className="headerSearchContiner">
                    <div className="workContiner">
                        <img src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/header/logo.jpg" alt="logo" />
                        <div className="headerSearchCut"></div>
                        <div className="headerSearchTip">
                            <div>
                                <span>公民的专属</span>
                                <strong>私人律师</strong>
                            </div>
                            <div>
                                <span>企业的专属</span>
                                <strong>法律顾问</strong>
                            </div>
                        </div>
                        <div className="headerSearchMobil">
                            <span className="iconfont icon-icon-test34"></span>
                            <div>
                                <p className="mobil-text">全国免费热线</p>
                                <p className="mobil">400-777-7091</p>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* 头部导航栏 */}
        <Fragment>{this.navList()}</Fragment>
      </div>
    );
  }

  navgatorTo(path) {
    //不知道有没有导航守卫
    //如果有可以考虑用store里面放这个数据
    //然后保证hash
    if (this.state.curNav === path) return;
    this.setState({
      curNav: path,
    });
  }
}

export default Header;
