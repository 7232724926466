
import React from 'react';
import { observer } from 'mobx-react'
import './m_header.css';
import BaseComponent from '../../base';
// import RouterStore from '../../store/router';

import { Link } from 'react-router-dom';
import logoHeader from '../../assets/m_header/logo.jpg';
import menuHeader from '../../assets/m_header/menu.jpg'

@observer
class MobileHeader extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            navList: [
                {
                    name: '首页',
                    path: 'm_entry'
                }, {
                    name: '私人律师服务',
                    path: 'm_coustomerLawyer'
                }, {
                    name: '企业法律顾问',
                    path: 'm_companyLawyer'
                }, {
                    name: '合作代理',
                    path: 'm_collaborate'
                }, {
                    name: '找律师',
                    path: 'm_findLawyer'
                }, {
                    name: '经典案例',
                    path: 'm_lawyerConsult'
                }
                , {
                    name: '关于我们',
                    path: 'm_aboutUs'
                }
            ],
            hidden: "none",
        }
    }
    tartgetBtn = () => {
       
        this.setState({
            hidden: this.state.hidden === "block" ? "none" : "block",
        });

    }

    render() {
        return (
            <div className="mheaderContiner">
                <div className="mheaderSearchContiner">
                    <img src={menuHeader} alt="" onClick={() => this.tartgetBtn()} />
                    <img src={logoHeader} alt="" />
                    <img className='mheaderImgRight' src="" alt="" />
                </div>
                <ul className="mheaderNavUl" ref="myInput"
                    style={{ display: this.state.hidden }}
                >
                    {
                        this.state.navList.map(value => {
                            return (
                                <li key={value.path}
                                    onClick={() => this.navgatorTo(value.path)}
                                >
                                    <Link className="mheaderLink" style={{vlinkColor:"white"}} to={`/${value.path}`}>{value.name}</Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }

    navgatorTo(path) {
        this.setState({
            hidden:"none"
        })
        if (this.state.curNav === path) return;
        this.setState({
            hidden: "none",
            curNav: path
        })
    }
}

export default MobileHeader;
