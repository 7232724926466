import React, { Component } from "react";
import "./m_dropDown.css";
import dropDown from "../../../assets/m_findLawyer/下拉.jpg";
class MobileDropDown extends Component {
  state = {
    isShow: false,
    isClick: false,
  
  };
  render() {
    let { isClick, isShow,} = this.state;
    let { dataList = [], str ,active } = this.props;
    return (
      <div
        className="mdropDown"
        onClick={() => {
          this.setState({ isClick: !isClick });
        }}
      >
        <div className="mprovinceTitle">
          <span className="mprovince">{str}</span>
          <ul className={isShow ? "mdropDown_list_show " : "mdropDown_list"}>
            {dataList.length ? (
              dataList.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      this.props.selectOption(item);
                      this.setState({ isShow: !isShow, active: index });
                    }}
                    className={active === index ? "activeClass" : "activeIndex"}
                  >
                    {item.name}
                  </li>
                );
              })
            ) : (
              <li></li>
            )}
          </ul>

          
          {isShow === false && (
            <div>
              {dataList.length ? (
                <span
                  className="mprovince1"
                  onClick={() => {
                    this.setState({ isShow: !isShow });
                  }}
                >
                  更多
                  <img className="miconshows" src={dropDown} alt="" />
                </span>
              ) : (
                ""
              )}
            </div>
          )}
          {isShow === true && (
            <div>
              <span
                className="mprovince1"
                onClick={() => {
                  this.setState({ isShow: !isShow });
                }}
              >
                收起
                <img className="iconshows miconshows" src={dropDown} alt="" />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default MobileDropDown;
