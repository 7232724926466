import React from "react";
import BaseComponent from "./../../base";
import Comcerns from "../mobile_concern/m_concern";
import Pagination from "../mobile_pagination/m_pagination";
import { getNewDate } from "../../util/date";
// 加载中 组件
import { DotLoading } from "antd-mobile";
//css
import "./m_lawyerConsult.css";
class MobileLawyerConsult extends BaseComponent {
  state = {
    pageNo: 1,
    pageSize: 8,
    total: 4,
    count: 0,
    dataList: [],
    active: 0,
  };
  componentDidMount() {
    this.getList();
    this.changeNav("m_lawyerConsult");
  }
  // 请求列表参数
  getList = () => {
    let { pageNo, pageSize } = this.state;
    this.ajax("getWebTypicalCaseList", {
      pageNo,
      pageSize,
      type: 2,
      publish: 1,
    }).then((res) => {
      if (res.status) {
        let pages = Math.ceil(res.result.count / pageSize);
        this.setState({
          dataList: res.result.entities,
          total: pages,
          count: res.result.count,
        });
        this.wowInit();
      }
    });
  };
  getCurrentPage = (currentPage) => {
    this.setState(
      {
        pageNo: currentPage,
      },
      () => {
        this.getList();
      }
    );
  };
  toInfo = (id) => {
    this.props.history.push({
      pathname: "/MobileInfo",
      query: { id },
    });
  };
  render() {
    let { count, pageSize, active } = this.state;
    let list = [
      { title: "全部" },
      { title: "最新" },
      { title: "热门关注点" },
      { title: "最近浏览" },
    ];
    return (
      <div>
        <ul className="lawyerConsultUl">
          {list.map((item, index) => {
            return (
              <li
                className={active === index ? "activeStyle" : ""}
                onClick={() => {
                  this.setState({
                    active: index,
                  });
                }}
                key={index}
              >
                {item.title}
              </li>
            );
          })}
        </ul>
        <Comcerns />
        <div className="mlawyerConsult_conter ">
          {this.lawyerConsultItem()}

          {count > pageSize ? (
            <Pagination
              pageNo={this.state.pageNo}
              total={this.state.total}
              pageCallbackFn={this.getCurrentPage}
            />
          ) : null}
        </div>
      </div>
    );
  }

  lawyerConsultItem = () => {
    let { dataList } = this.state;
    return (
      <div>
        {dataList.length ? (
          <ul className="mlawyerConsult_content">
            {dataList.map((item, index) => {
              return (
                <li
                  key={index}
                  className=" mlawyerConsult_item"
                  onClick={() => {
                    this.toInfo(item.id);
                  }}
                >
                  <div className="mlawyerConsult_item_info">
                    <h2>{item.title}</h2>
                    <div className="mlawyerConsult_time">
                      <p>发布：{getNewDate(item.createTime)}</p>
                    </div>
                  </div>
                  <div
                    style={{
                      background: `url(${item.thumbnailPath}) no-repeat center`,
                    }}
                    className="mlawyerConsult_item_img"
                  ></div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="dotloading">
            <DotLoading color="#4b5a98" />
          </div>
        )}
      </div>
    );
  };
}

export default MobileLawyerConsult;
