export const media = () => {//移动端媒体查询动态设置rem
    // import {useWindowSize} from '@/utils/hooks/useWindowSize.js';
    // const {width,height} = useWindowSize();
    var oWidth = document.documentElement.clientWidth;
    if (oWidth < 1366) {
        window.onload = function () {
            getRem(750, 100)
        };
        window.onresize = function () {
            getRem(750, 100)
        };
    }
    function getRem(pwidth, prem) {
        var html = document.getElementsByTagName("html")[0];
        var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
        html.style.fontSize = oWidth / pwidth * prem + "px";
    }
}
