import { observable, action } from 'mobx';

class RouterStore {
    @observable curNav = 'entry';
    
    @action
    changeNav(path) {
        this.curNav = path
    }
}

export default new RouterStore();