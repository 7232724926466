
const homeSliders = '/communal/content/official/homeSliders'; //首页轮播图
const typicalCases = '/communal/content/official/typicalCases';   //经典案例
const consult = "/communal/content/web/addVisitor";//咨询合伙人项目
const getWebTypicalCaseList = "/communal/content/web/getWebTypicalCaseList"; //经典案例
const getlawyerConsultInfo = "/communal/content/web/getWebTypicalCase"//经典案例详情
const lawyers = "/communal/lawyer/query/lawyers";//律师列表
const  allAreas = "/communal/area/allAreas"//获取地区
const  lawCategories = "/communal/law/lawCategories"//获取法律分类

const companyMemberCard = '/communal/memberCard/companyMemberCard'; //企业会员卡
const personalMemberCard = '/communal/memberCard/personalMemberCard'; // 个人会员卡
export default {
    homeSliders,
    typicalCases,
    consult,
    getWebTypicalCaseList,
    getlawyerConsultInfo,
    lawyers,
    allAreas,
    lawCategories,
    companyMemberCard,
    personalMemberCard
}