import React from "react";
import BaseComponent from "../../base";
import "./m_footer.css";
class MobileFooter extends BaseComponent {
  collaborate() {
    this.props.history.push({
      pathname: "m_collaborate",
    });
  }

  render() {
    let year = new Date().getFullYear();
    return (
      <div className="mfooterContiner">
        <section className="footerFirst">
          <p className="footerPhone">全国免费热线</p>
          <p className="footerPhoneNumber">400-777-7091</p>
          <p className="footer-address">公司地址</p>
          <p className="footerAddress">
            成都市金牛区一环路西三段31号嘉园大厦20楼
          </p>
        </section>
        <div className="footText">
          <p>公民的专属律师 企业的法律顾问</p>
          <p>Exclusive legal services</p>
        </div>

        <section className="footAdress">
          <p>
            <a className="addressGxb" href="https://beian.miit.gov.cn/">署ICP备19013309号-1</a>
            友情链接申请号：400-777-7091
          </p>
          <p>
            版权所有© {year} 成都市律政时代信息科技有限公司
            任何个人或者单位、团体不得抄袭本网站上的所有内容，含文字、图片等信息，违者必究
          </p>
        </section>
      </div>
    );
  }
}

export default MobileFooter;
