import React from "react";
import BaseComponent from "../../../base";
import DropDown from "../mobile_dropDown/m_dropDown";
import flushed from '../../../assets/m_collaborate/flushed.jpg'
// css
import "./m_selectCity.css";

class MobileSelectCity extends BaseComponent {
  state = {
    // 数据
    provinceList: [],
    cityList: [],
    regionList: [],
    beGoodAt: [], //擅长领域
    isShow: true,
    active: -1,
  };
  componentDidMount() {
    this.getDataList();
  }
  // 获取省市区
  getDataList = () => {
    this.ajax("allAreas").then((res) => {
      if (res.status) {
        this.setState({
          provinceList: res.result,
        });
      }
    });
  };
  // 选择省
  selctProvince = (value) => {
    let obj = {
      province: value,
      city: {},
      region: {},
    };
    if (value.areas.length) {
      this.setState({
        cityList: value.areas,
      });
    }
    this.props.selctProvinceItem(obj);
  };
  //选择市
  selctCity = (value) => {
    let obj = {
      city: value,
      region: {},
    };
    if (value.areas.length) {
      this.setState({
        regionList: value.areas,
      });
    }
    this.props.selctCityItem(obj);
  };
  // 选择区
  selctRegion = (value) => {
    this.props.selctRegionItem({ region: value });
  };
  // 重置按钮
  resetBtn = () => {
    this.setState({
      provinceList: [],
      cityList: [],
      regionList: [],
    });
    this.getDataList();
  };
  render() {
    let { provinceList, cityList, regionList } = this.state;
    let { province, city, region, str } = this.props;
    return (
      <div className="mselectCity">
        <div className="searchString">
          <div className="searchCondition">搜索条件：</div>
          <ul className="searchStringUl">
            {province.name && (
              <li className="breadLi">
                {province.name}
                <i
                  onClick={() => {
                    this.props.deletehandler(province.name);
                    this.getDataList();
                  }}
                >
                  x
                </i>
              </li>
            )}

            {city.name && (
              <li className="breadLi">
                {city.name}
                <i
                  onClick={() => {
                    city.name = undefined;
                    this.getDataList();
                  }}
                >
                  x
                </i>
              </li>
            )}
            {region.name && (
              <li className="breadLi">
                {region.name}
                <i
                  onClick={() => {
                    region.name = undefined;
                    this.getDataList();
                  }}
                >
                  x
                </i>
              </li>
            )}
          </ul>
          <div
          className="btnCity"
            onClick={() => {
              province.name = undefined;
              city.name = undefined;
              region.name = undefined;
              this.resetBtn();
            }}
          >
            <img src={flushed} alt="" />
            重置
          </div>
        </div>
        <DropDown
          obj={province}
          dataList={provinceList}
          str="省份:"
          selectOption={(value) => {
            this.selctProvince(value);
          }}
        />
        {cityList.length > 0 && (
          <DropDown
            obj={city}
            dataList={cityList}
            cityLength={cityList}
            selectOption={(value) => {
              this.selctCity(value);
            }}
          />
        )}

        {regionList.length > 0 && (
          <DropDown
            obj={region}
            dataList={regionList}
            selectOption={(value) => {
              this.selctRegion(value);
            }}
          />
        )}
      </div>
    );
  }
}

export default MobileSelectCity;
