import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {ComputerRouter,MobileRouter} from './router';


import {media} from "./mymedia/media.js"
if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
) {
    console.log("移动端入口")
    media();
    ReactDOM.render(<div id="mobile"><MobileRouter /></div>, document.getElementById('root'));
} else {
    console.log("PC端入口")
    ReactDOM.render(<div id="PC"><ComputerRouter /></div>, document.getElementById('root'));
}

serviceWorker.unregister();
