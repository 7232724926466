import React, { Suspense, lazy } from "react";
import App from "./App";
import MobileApp from "./mobile/mobile_app";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import BaseComponent from "./base";

import MobileEnter from "./mobile/mobile_entry/m_entry"; //移动端首页
import MobileCoustomerLawyer from "./mobile/mobile_coustomerLawyer/m_coustomerLawyer"; //移动端私人律师服务
import MobileCollaborate from "./mobile/mobile_collaborate/m_collaborate"; //移动端合作代理
import MobileFindLawyer from "./mobile/mobile_findLawyer/m_findLawyer"; //移动端找律师
import MobileLawyerConsult from "./mobile/mobile_lawyerConsult/m_lawyerConsult"; //移动端经典案例
import MobileAboutUs from "./mobile/mobile_aboutUs/m_aboutUs"; //移动端关于我们
import MobileCompanyLawyer from "./mobile/mobile_companyLawyer/m_companyLawyer"; //移动端企业法律顾问
import MobileInfo from "./mobile/mobile_lawyerConsult/info/m_info"; //移动端案例详情
import MobileFindLawyerInfo from "./mobile/mobile_findLawyer/info/m_findInfo"; //移动端律师详情
import MobileBuy from "./mobile/mobile_coustomerLawyer/mobile_buy/m_buy"; //移动端购买页面

const Entry = lazy(() => import("./pages/entry/entry"));
const CoustomerLawyer = lazy(() =>
  import("./pages/coustomerLawyer/coustomerLawyer")
);
const Companylawyer = lazy(() => import("./pages/companyLawyer/companyLawyer"));
const Collaborate = lazy(() => import("./pages/collaborate/collaborate"));
const FindLawyer = lazy(() => import("./pages/findLawyer/findLawyer"));
const LawyerConsult = lazy(() => import("./pages/lawyerConsult/lawyerConsult"));
const LawyerConsultInfo = lazy(() =>
  import("./pages/lawyerConsult/info/lawyerInfo")
);
const AboutUs = lazy(() => import("./pages/aboutUs/aboutUs"));
const findLawyerInfo = lazy(() =>
  import("./pages/findLawyer/info/findLawyerInfo")
);
class ComputerRouter extends BaseComponent {
  render() {
    return (
      <Router>
        <Suspense fallback={<div>...</div>}>
          <Switch>
            <App>
              <Route exact path="/" component={Entry}></Route>
              <Route path="/entry" component={Entry}></Route>
              <Route
                path="/coustomerLawyer"
                component={CoustomerLawyer}
              ></Route>
              <Route path="/companylawyer" component={Companylawyer}></Route>
              <Route path="/collaborate" component={Collaborate}></Route>
              <Route path="/findLawyer" component={FindLawyer}></Route>
              <Route path="/findLawyerInfo" component={findLawyerInfo}></Route>
              <Route path="/lawyerConsult" component={LawyerConsult}></Route>
              <Route
                path="/LawyerConsultInfo"
                component={LawyerConsultInfo}
              ></Route>
              <Route path="/aboutUs" component={AboutUs}></Route>
            </App>
          </Switch>
        </Suspense>
      </Router>
      // <BrowserRouter>
      //     <App>
      //   <Route exact path="/" component={Entry}></Route>
      //   <Route path="/entry" component={Entry}></Route>
      //   <Route path="/coustomerLawyer" component={CoustomerLawyer}></Route>
      //   <Route path="/companylawyer" component={Companylawyer}></Route>
      //   <Route path="/collaborate" component={Collaborate}></Route>
      //   <Route path="/findLawyer" component={FindLawyer}></Route>
      //   <Route path="/findLawyerInfo" component={findLawyerInfo}></Route>
      //   <Route path="/lawyerConsult" component={LawyerConsult}></Route>
      //   <Route path="/LawyerConsultInfo" component={LawyerConsultInfo}></Route>
      //   <Route path="/aboutUs" component={AboutUs}></Route>
      //   <Route path="/buy" component={Buy}></Route>
      //     </App>
      // </BrowserRouter >
    );
  }
}
class MobileRouter extends BaseComponent {
  render() {
    return (
      <BrowserRouter>
        <MobileApp>
          <Route exact path="/" component={MobileEnter}></Route>
          <Route path="/m_entry" component={MobileEnter}></Route>
          <Route
            path="/m_coustomerLawyer"
            component={MobileCoustomerLawyer}
          ></Route>
          <Route
            path="/m_companyLawyer"
            component={MobileCompanyLawyer}
          ></Route>
          <Route path="/m_collaborate" component={MobileCollaborate}></Route>
          <Route path="/m_findLawyer" component={MobileFindLawyer}></Route>
          <Route
            path="/m_LawyerConsult"
            component={MobileLawyerConsult}
          ></Route>
          <Route path="/MobileInfo" component={MobileInfo}></Route>
          <Route
            path="/MobileFindLawyerInfo"
            component={MobileFindLawyerInfo}
          ></Route>
          <Route path="/m_buy" component={MobileBuy}></Route>
          <Route path="/m_aboutUs" component={MobileAboutUs}></Route>
        </MobileApp>
      </BrowserRouter>
    );
  }
}
export { ComputerRouter, MobileRouter };
