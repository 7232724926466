import React from "react";
import BaseComponent from "../../../base";
import "./m_buy.css";
import Title from "../../../compontents/title_coust/title_coust";

import mstar from "../../../assets/m_buy/m_buy_star.png";
import mchecked from "../../../assets/m_buy/m_buy_07.jpg";
import mBuyBigimg from "../../../assets/m_buy/m_buy_03.jpg";
class MobileBuy extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      flowId: 2,
    };
  }
  render() {
    return (
      <div className="mbuyProductWorkContiner">
        {this.productDetail()}
        {this.advantageousproduct()}
        {this.ServiceIntroduction()}
        {this.serviceProcess()}
      </div>
    );
  }
  // 商品详情
  productDetail() {
    return (
      <div className="mproductDetailContiner">
        <div className="mproductDetailImg">
          <img src={mBuyBigimg} alt="" />
        </div>
        <div className="mbuyImgFont">
          <p>私人律师服务 家庭版详情</p>
          <Title title="私人律师顾问" text="Private Family Lawyer" />
          <ul className="mBuyUl">
            <li>
              <p>一星家享卡</p>
              <div className="mStarBox">
                <img src={mstar} alt="" />
              </div>
            </li>
            <li>
              <p>二星家享卡</p>
              <div className="mStarBox">
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
              </div>
            </li>
            <li>
              <p>三星家享卡</p>
              <div className="mStarBox">
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
              </div>
            </li>
            <li>
              <p>四星家享卡</p>
              <div className="mStarBox">
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
              </div>
            </li>
            <li>
              <p>五星家享卡</p>
              <div className="mStarBox">
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
                <img src={mstar} alt="" />
              </div>
            </li>
            <li className="mBuyLastLi">
              <p>五星家享卡</p>
              <div className="mStarBox">
                <img src={mstar} alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div className="mBuyBtn">
          <button>立即购买</button>
          <button>在线咨询</button>
        </div>
        <ul className="mBuyHorizonalStripe">
          <li>已服务：18980</li>
          <li>已咨询：9668</li>
          <li>好评率：98%</li>
        </ul>
        <p className="mBuyTip">
          特殊声明：本服务仅限中国大陆（不含港澳台）法律服务
        </p>
        <ul className="mBuyChecked">
          <li>
            <img src={mchecked} alt="" />
            <p>快速办理</p>
          </li>
          <li>
            <img src={mchecked} alt="" />
            <p>材料免费寄</p>
          </li>
          <li>
            <img src={mchecked} alt="" />
            <p>流程掌控</p>
          </li>
        </ul>
        <p className="mBuyTip">
          全新模式打造的全方位一站式综合保障性法律服务平台，解决的是老百姓不在为法律问题担忧
        </p>
      </div>
    );
  }
  //   产品优势
  advantageousproduct() {
    return (
      <div className="madvantageousproduct">
        <Title title="产品优势" text="Product Advantages" />
        {this.flowContiner()}
      </div>
    );
  }
  changeFlowId(_id) {
    this.setState({
      flowId: _id,
    });
  }
  flowContiner() {
    let buyVs = [
      {
        title: "传统法律服务",
        text1: "费用不统一，乱收费等现象",
        text2: "不注重防范，等事情已经发生了才开始注意",
        text3: "能力水平参差不齐，不够专业，在办案中无法保证用户的隐私",
        text4: "单个律师都是单枪匹马精力有限",
        text5: "缺少有效监督，律师办案不尽心尽力",
      },
      {
        title: "VS",
        text1: "服务费用",
        text2: "服务模式",
        text3: "隐私保护",
        text4: "服务效率",
        text5: "权益保障",
      },
      {
        title: "律政时代法律服务",
        text1: "私人家庭律师，套餐最低199元一年",
        text2: "融入保险思维，注重预防风险，有完整的诉讼管理流程",
        text3: "每个案件独立报告，只与当事人实时跟进",
        text4: "工厂流水化作业，各司其职，专业人做专业事，高效配合",
        text5: "全国方位售后服务，明确的制度化管理，全方位保护客户权益",
      },
    ];
    return (
      <div>
        {/* 共享律师的优势有哪些？ */}
        <div className="buyAdvantage">
          共享律师的 <span>优势有哪些？</span>
        </div>
        <ul className="buyVs">
          {buyVs.map((item, index) => {
            return (
              <li key={index}>
                <h4>{item.title}</h4>
                <p>{item.text1}</p>
                <p>{item.text2}</p>
                <p>{item.text3}</p>
                <p>{item.text4}</p>
                <p>{item.text5}</p>
              </li>
            );
          })}
        </ul>
        {/* 用心服务 专属一对一*/}
        <div className="buyAdvantage">
          用心服务<span> 专属一对一</span>
        </div>
        <ul className="buyImg">
          <li>
            <img
              src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/buy/listen.png"
              alt=""
            />
            <h5>极速高效</h5>
          </li>
          <li>
            <img
              src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/buy/good.png"
              alt=""
            />
            <h5>省心省力</h5>
          </li>
          <li>
            <img
              src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/buy/lock.png"
              alt=""
            />
            <h5>严格保密</h5>
          </li>
        </ul>
      </div>
    );
  }

  //   服务介绍
  ServiceIntroduction() {
    let icontSevice = [
      {
        iconfont: "iconfont icon-icon-test1",
        title: "婚姻家庭",
      },
      {
        iconfont: "iconfont icon-icon-test19",
        title: "债务债权",
      },
      {
        iconfont: "iconfont icon-icon-test39",
        title: "房产买卖",
      },
      {
        iconfont: "iconfont icon-icon-test40",
        title: "劳动人事",
      },
      {
        iconfont: "iconfont icon-icon-test7",
        title: "医疗事故",
      },
      {
        iconfont: "iconfont icon-icon-test24",
        title: "消费维权",
      },
      {
        iconfont: "iconfont icon-icon-test38",
        title: "损害赔偿",
      },
      {
        iconfont: "iconfont icon-icon-test42",
        title: "网络维权",
      },
    ];
    return (
      <div className="mServiceIntroduction ">
        <Title title="服务介绍" text="Service Introduction" />
        {/* 遇到这些问题 怎么办？*/}
        <div className="buyAdvantage">
          遇到这些问题<span>怎么办？</span>
        </div>
        <ul className="mproductImg">
          <li>
            <div>
              <i className="iconfont icon-icon-test1"></i>
            </div>
            <div>
              <h2>婚姻家庭</h2>
              <p>如何获得孩子的抚养权？</p>
              <p>对方有外遇财产分割有争议？夫妻财务...</p>
            </div>
          </li>
          <li>
            <div>
              <h2>劳动纠纷</h2>
              <p>没有签合同被公司辞退不给工资怎么办？</p>
              <p>在上班途中受伤公司不管不顾应该怎么办</p>
            </div>
            <div>
              <i className="iconfont icon-icon-test40"></i>
            </div>
          </li>
          <li>
            <div>
              <i className="iconfont icon-icon-test24"></i>
            </div>
            <div>
              <h2>消费维权</h2>
              <p>在销售人员的欺骗下购买了劣质产品怎么办？</p>
              <p>使用了商家的产品出现不良反应的怎么办？</p>
            </div>
          </li>
          <li>
            <div>
              <h2>交通出行</h2>
              <p>莫名其妙被碰瓷了怎么办？常见的开门杀怎么处理？</p>
              <p>行人闯红灯发生意外了怎么办?</p>
            </div>
            <div>
              <i className="iconfont icon-icon-test15"></i>
            </div>
          </li>
          <li>
            <div>
              <i className="iconfont icon-icon-test11"></i>
            </div>
            <div>
              <h2>其他法律问题</h2>
              <p>
                债务债权、房屋买卖、网络维权、物业纠纷、损害赔偿、人事行政...
              </p>
            </div>
          </li>
        </ul>
        <ul className="incoSevice">
          {icontSevice.map((item, index) => {
            return (
              <li className="incoStyle" key={index}>
                <div>
                  <i className={item.iconfont}></i>
                </div>
                <p>{item.title}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  // 服务流程
  serviceProcess() {
    return (
      <div className="serviceProcess">
        <Title title="服务流程" text="Service Process" />

        <div className="buyAdvantage">
          专业高效的<span>服务流程</span>
        </div>
        <ul className="flowChart">
          <li>
            <img
              src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/buy/buy1.png"
              alt=""
            />
            <div>
              <p>通过在线咨询等渠道购买我们的服务</p>
              <p>平台分配律师并确认电话沟通具体详情</p>
              <p>根据个案提供专业的解决方案</p>
            </div>
            <img
              src="https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/buy/buy2.png"
              alt=""
            />
            <div>
              <div>
                <p>服务完成 </p>
                <p>用户评价</p>
              </div>
              <div>
                <p>律师跟进</p>
                <p>并反馈进程</p>
              </div>

              <p>等待律师及用户再次确认案件方案</p>
            </div>
          </li>
        </ul>
        <div className="buyAdvantage">
          律政时代的<span>保障及承诺</span>
        </div>
        <ul className="buyPromise">
          <li>
            <div className="iconfontBox">
              <i className="iconfont icon-icon-test66"></i>
            </div>
            <div>
              <h5>我们承诺</h5>
              <p>公平、公正、专业、专注、诚信、互惠</p>
              <p>极致的服务意识，彰显出专业的价值</p>
            </div>
          </li>
          <li>
            <div className="iconfontBox">
              <i className="iconfont icon-icon-test58"></i>
            </div>
            <div>
              <h5>售后服务</h5>
              <p>1.服务热线：400-777-7091；</p>
              <p>2.点击在线人工服务，描述详情；</p>
              <p>3.通过微信公众号，APP应用等方式，联系我们。</p>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default MobileBuy;
