import React, { Component } from "react";
import "./m_pagination.css";

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: props.pageNo, //当前页码
            groupCount: 5, //页码分组，显示7个页码，其余用省略号显示
            startPage: 1,  //分组开始页码
            totalPage: props.total, //总页数
        }
    }
    //上一页事件
    prePageHandeler(that) {
        let { pageNo } = that.props;
        if (--pageNo === 0) {
            return false
        }
        that.pageClick(pageNo)
    }

    //下一页事件
    nextPageHandeler(that) {
        let { pageNo } = that.props;
        let { totalPage } = that.state
        if (++pageNo > totalPage) {
            return false
        }
        that.pageClick(pageNo)
    }
    //页码点击
    pageClick(currentPage) {
        const { groupCount } = this.state
        const getCurrentPage = this.props.pageCallbackFn;
        //当 当前页码 大于 分组的页码 时，使 当前页 前面 显示 两个页码
        if (currentPage >= groupCount) {
            this.setState({
                startPage: currentPage - 2,
            })
        }
        if (currentPage < groupCount) {
            this.setState({
                startPage: 1,
            })
        }
        //第一页时重新设置分组的起始页
        if (currentPage === 1) {
            this.setState({
                startPage: 1,
            })
        }
        this.setState({
            currentPage
        })
        //将当前页码返回父组件
        getCurrentPage(currentPage)
    }
    render() {

        return (
            <ul className="mpagination">
                {this.createPage()}
            </ul>
        )
    }
    createPage = () => {
        const { totalPage } = this.state;
        let pages = []
        //上一页
        pages.push(this.nextPages(0, 1, "上一页", this.prePageHandeler))
        //下一页
        pages.push(this.nextPages(totalPage + 1, totalPage, "下一页", this.nextPageHandeler))
        return pages;
    }
    // 第一页，最后一页
    firstPage = (page) => {
        const { pageNo } = this.props;
        return (
            <li className={pageNo === page ? "mpages mactivePage mpagination_nomore" : "mpages mpagination_hovers"} key={page} onClick={() => { this.pageClick(page) }}>{page}</li>
        )
    }
    // 上一页 下一页
    nextPages = (index, page, text, func) => {
        const { pageNo } = this.props;
        return (
            <li className={pageNo === page ? "mpagination_nomore" : "mpagination_morePage"} onClick={() => { func(this) }} key={index}>{text}</li>
        )
    }
}


export default Pagination