import React, { Fragment } from "react";
import BaseComponent from "../../base/index";
//css
import "./m_companyLawyer.css";
//title
import CoustTitle from "../../compontents/title_coust/title_coust";
// image
import mCompanyImg from "../../assets/companyLawyer/companyLawyer-Wed_11.jpg";
import mCompanyImg1 from "../../assets/companyLawyer/companyLawyer-Wed_14.jpg";
import mCompanyImg2 from "../../assets/companyLawyer/companyLawyer-Wed_16.jpg";
import mCompanyImg3 from "../../assets/companyLawyer/companyLawyer-Wed_18.jpg";
import mCompanyImg4 from "../../assets/companyLawyer/companyLawyer-Wed_20.jpg";
class MobileompanyLawyer extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      caseCategory: "",
      city: "",
      mobile: "",
      name: "",
      titleInfo: "",
      company: "",
      adress: "",
    };
  }
  // 判断字段
  isValue = (string) => {
    this.setState({ titleInfo: string });
    setTimeout(() => {
      this.setState({ titleInfo: "" });
    }, 2000);
  };
  // 提交咨询
  SubmitForm = () => {
    let { caseCategory, city, mobile, name, company, adress } = this.state;
    let phoneReg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
    if (!name) {
      this.isValue("请输入姓名");
      return;
    }
    if (!company) {
      this.isValue("请输入公司名称");
      return;
    }
    if (!mobile) {
      this.isValue("请输入电话");
      return;
    }
    if (!phoneReg.test(mobile)) {
      this.isValue("电话号码输入不正确，请重新输入");
      return;
    }

    if (!caseCategory) {
      this.isValue("请输入案件类型");
      return;
    }
    if (!city) {
      this.isValue("请输入城市");
      return;
    }
    if (!adress) {
      this.isValue("请输入公司地址");
      return;
    }
    this.ajax("consult", {
      caseCategory,
      name,
      mobile,
      city,
      company,
      adress,
    }).then((res) => {
      console.log(res);
      if (res.status) {
        this.isValue("客服将在五分钟内与您联系，请保持电话畅通!");
        this.setState({
          caseCategory: "",
          name: "",
          mobile: "",
          city: "",
          company: "",
          adress: "",
        });
      }
    });
  };
  render() {
    return (
      <Fragment>
        <div className="companyLawyerBigImg"> </div>
        <div className={"box-info"}>
          {this.Chioce()}
          {this.formMenu()}
        </div>
        {this.seriveInfo()}
        {this.SeriveCard()}
        {this.seriveCase()}
      </Fragment>
    );
  }

  step() {
    const stepInfo = [
      {
        image:
          "https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/companyLawyer/manner1.jpg",
        step: "第一步",
        title: "购买服务",
        content: "客户购买服务或通过在线咨询等渠道购买我们的服务",
      },
      {
        image:
          "https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/companyLawyer/manner2.jpg",
        step: "第二步",
        title: "匹配律师",
        content: "平台分配律师并确认电话沟通具体详情",
      },
      {
        image:
          "https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/companyLawyer/manner3.jpg",
        step: "第三步",
        title: "模拟开庭",
        content: "模拟法庭开庭时候能遇见的问题及时提出解决办法",
      },
      {
        image:
          "https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/companyLawyer/manner4.jpg",
        step: "第四步",
        title: "出庭准备",
        content: "将所有资料案件的证据以及笔录准备充分在开庭前提交给法院",
      },
      {
        image:
          "https://lzsd.oss-cn-shenzhen.aliyuncs.com/www.lzsd/companyLawyer/manner5.jpg",
        step: "第五步",
        title: "案件归结",
        content: "将客户资料转交专人保管做到严格保密",
      },
    ];
    return (
      <div>
        <CoustTitle title="迄今为止的服务" text="Service To Date" />
        <ul className={"step"}>
          {stepInfo.map((item, index) => (
            <li key={`step${index}`} className={"step-box"}>
              <img src={item.image} alt=""></img>
              <div className={"step-step"}>{item.step}</div>
              <div className={"step-title"}>{item.title}</div>
              <div className={"step-content"}>{item.content}</div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  //   流程把控、公平公正、认证严谨、全力以赴
  seriveCase() {
    return (
      <div className="m_seriveCase_all">
        <CoustTitle
          title=" 流程把控、公平公正、认证严谨、全力以赴"
          text="Process Control"
        />
        <ul className={"case"}>
          <li className="caseLi">
            <img className="caseLiFirst" src={mCompanyImg} alt="" />
          </li>
          <li className="caseLi case-direction">
            <div>
              <h3>匹配律师</h3>
              <p>平台分配律师并确认电话沟通具体详情</p>
            </div>
            <div className="mCompanyImg4"><img src={mCompanyImg1} alt="" /></div>
          </li>
          <li className="caseLi case-direction">
            <div>
              <h3>模拟开通</h3>
              <p>模拟法庭开庭时候能遇见的问题并及时提出解决办法</p>
            </div>
            <div className="mCompanyImg4"><img src={mCompanyImg2} alt="" /></div>
          </li>
          <li className="caseLi case-direction">
            <div>
              <h3>出庭准备</h3>
              <p>将所有资料案件的证据以及笔录准备充分在开庭前提交给法院</p>
            </div>
            <div className="mCompanyImg4"><img src={mCompanyImg3} alt="" /></div>
          </li>
          <li className="caseLi case-direction">
            <div>
              <h3>案件归结</h3>
              <p>将客户资料转交专人保管，做到严格保密</p>
            </div>
            <div className="mCompanyImg4"><img src={mCompanyImg4} alt="" /></div>
          </li>
        </ul>
      </div>
    );
  }
  //   服务领域
  seriveInfo() {
    const info = [
      {
        title: "企业合同文书",
      },
      {
        title: "企业劳动用工",
      },
      {
        title: "企业知识产权",
      },
      {
        title: "企业经济纠纷",
      },
      {
        title: "企业股权专项",
      },
      {
        title: "企业刑事风险",
      },
      {
        title: "企业投资融资",
      },
      {
        title: "企业高管私人律师",
      },
      {
        title: "企业资源整合增值",
      },
    ];
    return (
      <div className="m_service_all">
        <div className="m_service_title">
          <p>Service Area</p>
          <h3>服务领域</h3>
        </div>
        <ul className={"info"}>
          {info.map((item, index) => (
            <li key={`seriveInfo${index}`} className={"info_box"}>
              <div className={"info_box_title"}>{item.title}</div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  //   专业法律服务，为您争取更大利益
  SeriveCard() {
    let list = [
      {
        title: "及时应答",
        text: "  提供7*24小时全年无休，一对一电话咨询并联合线下提出多渠道的沟通方案让用户得到有效服务，解决燃眉之急",
      },
      {
        title: "未雨绸缪",
        text: "  提供7*24小时全年无休，一对一电话咨询并联合线下提出多渠道的沟通方案让用户得到有效服务，解决燃眉之急",
      },
      {
        title: "自有法务部门",
        text: " 核心办案人员5年以上办案经验千余人 线上合作律师更是有上万名",
      },
      {
        title: "判案律师",
        text: ' 根据企业需求,推荐具有专长的专业律师办理避免包治百病的"万金油"现象',
      },
    ];

    return (
      <div className="m_seriveCard_all">
        <CoustTitle
          title="专业法律服务，为您争取更大利益"
          text="Legal Service"
        />
        <ul className={"serive"}>
          {list.map((item, index) => {
            return (
              <li key={`SeriveCard${index}`}>
                <div className={"serive_card"}>
                  <p>{item.title}</p>
                  <div className={"serive_content"}>{item.text}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  //   迄今为止的服务
  Chioce() {
    return (
      <div>
        <CoustTitle title="迄今为止的服务" text="Service To Date" />
        {this.DataList()}
      </div>
    );
  }

  DataList() {
    const list = [
      { title: "共计服务的用户量", num: "10+", unit: "万" },
      { title: "上市公司合作伙伴", num: "17+", unit: "家" },
      { title: "主要覆盖城市", num: "30", unit: "座" },
      { title: "旗下常驻律师", num: "300+", unit: "位" },

      { title: "迄今为止处理的案件", num: "6000+", unit: "件" },
    ];
    return (
      <ul className="data_list">
        {list.map((value, index) => {
          return (
            <li key={`DataList${index}`} className="box">
              <div className="box-item">
                <div className="data_list_num">{value.num}</div>
                <div className="data_list_unit">{value.unit}</div>
              </div>
              <div className="data_list_title">{value.title}</div>
            </li>
          );
        })}
      </ul>
    );
  }
  //  提交表单
  formMenu() {
    return (
      <div className="flawyerMenuContiner">
        <div className="fformContiner">
          <div className="fformBox wow bounceInLeft">
            <div className="fformTip">
              <div>
                <p>Choice is important</p>
                <h3>选择很重要，选对更重要</h3>
              </div>
              <div className="fformTipRight">
                <p>专业的法律服务 全国服务热线</p>
                <h3>400-777-7091</h3>
              </div>
            </div>
            <section>
              <div className="fformChooseContiner">
                <div>
                  <label>您的姓名</label>
                  <input
                    type="text"
                    placeholder="请输入您的名字"
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label>公司名称</label>
                  <input
                    type="text"
                    placeholder="请输入您的公司名称"
                    onChange={(e) => {
                      this.setState({ company: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label>您的电话</label>
                  <input
                    type="number"
                    placeholder="请输入您的电话"
                    onChange={(e) => {
                      this.setState({ mobile: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label>案件类型</label>
                  <input
                    type="text"
                    placeholder="请输入案件类型"
                    onChange={(e) => {
                      this.setState({ caseCategory: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label>所在城市</label>
                  <input
                    type="text"
                    placeholder="请输入所在城市"
                    onChange={(e) => {
                      this.setState({ city: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label>公司地址</label>
                  <input
                    type="text"
                    placeholder="请输入公司地址"
                    onChange={(e) => {
                      this.setState({ adress: e.target.value });
                    }}
                  />
                </div>
              </div>
            </section>
            <p
              className="prompting"
              style={{
                fontSize: ".22rem",
                transform: "translateY(32px)",
                color: "#4b5a98",
                marginBottom: ".3rem",
              }}
            >
              {this.state.titleInfo}
            </p>
            <button
              className="fsubmit-btn"
              onClick={() => {
                this.SubmitForm();
              }}
            >
              立即预约
            </button>
            <p className="ffrom-tip">
              {" "}
              线上客服7*24小时在线、免费咨询法律问题，紧急的事情不能等
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default MobileompanyLawyer;
