import React, {Component} from "react";
import "./m_concern.css";

class MobileComcerns extends Component{
    state = {
        comcernsItem:[
            '纠纷',
            '合同纠纷',
            '消费维权',
            '婚姻',
            '交通事故',
            '人事劳动',
            '债权债务',
            '刑事辩护',
            '公司相关',
            '损害赔偿',
            '房产纠纷',
            '建筑工程'
        ]
    }
    render(){
        let { comcernsItem } = this.state;
        return(
            
                <ul className='mcomcerns_content'>
                    {
                        comcernsItem.map((item, index) => {
                            return(
                                <li key={index} className="mcomcerns_item">{item}</li>
                            )
                        })
                    }
                </ul>
           
        )
    }
}
export default MobileComcerns