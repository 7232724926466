import React from "react";
import BaseComponent from "./../../base";
import "./m_aboutUs.css";
//title
import CoustTitle from "../../compontents/title_coust/title_coust";
import aboutUsData from "../../util/JSON/aboutUsData.json";
// img
import mAboutImg from "../../assets/aboutUs/aboutUs_05.jpg";
import mAboutImg1 from "../../assets/aboutUs/aboutUs_08.jpg";
import mAboutImg2 from "../../assets/aboutUs/aboutUs_11.jpg";
import mAboutUsImg from "../../assets/aboutUs/aboutUs_16.jpg";
import mAboutUsImg1 from "../../assets/aboutUs/aboutUs_18.jpg";
import mAboutUsImg2 from "../../assets/aboutUs/aboutUs_20.jpg";
import maboutArrowR from "../../assets/aboutUs/aboutUs_36.jpg";
import maboutArrowL from "../../assets/aboutUs/aboutUs_34.jpg";
import maboutTeam from "../../assets/aboutUs/aboutUs_27_27.jpg";
import maboutTeam1 from "../../assets/aboutUs/aboutUs_27_31.jpg";
class MobileAboutUs extends BaseComponent {
  componentWillMount() {
    this.changeNav("m_aboutUs");
  }
  state = {
    aboutThis: aboutUsData.aboutThis, //关于律政时代
    weDoings: aboutUsData.weDoings, //我们能做什么
    titleInfo: "",
    caseCategory: "",
    city: "",
    mobile: "",
    name: "",
    isSubmit: false,
  };
  componentDidMount() {
    this.wowInit();
  }
  // 提交咨询
  aboutUsBtn = () => {
    let { caseCategory, city, mobile, name } = this.state;
    let phoneReg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
    if (!name) {
      this.isValue("请输入姓名");
      return;
    }
    if (!mobile) {
      this.isValue("请输入电话");
      return;
    }
    if (!phoneReg.test(mobile)) {
      this.isValue("电话号码输入不正确，请重新输入");
      return;
    }
    if (!city) {
      this.isValue("请输入城市");
      return;
    }
    if (!caseCategory) {
      caseCategory = "咨询超级合伙人项目";
    }
    this.ajax("consult", {
      caseCategory,
      name,
      mobile,
      city,
    }).then((res) => {
      if (res.status) {
        this.isValue("信息提交成功，请耐心等待，我们将尽快跟您联系");
        this.setState({
          caseCategory: "",
          name: "",
          mobile: "",
          city: "",
        });
      }
    });
  };
  // 判断字段
  isValue = (string) => {
    this.setState({ titleInfo: string });
    setTimeout(() => {
      this.setState({ titleInfo: "" });
    }, 2000);
  };
  render() {
    return (
      <div className="maboutAll">
        <div className="mAboutBigImg"> </div>
        
        {this.aboutUsLzsd()}
        {this.aboutUsAdvertisement()}
        {this.aboutUsDoing()}
        {this.aboutUsTeam()}
        {this.aboutUsPositionform()}
      </div>
    );
  }

  // 关于律政时代
  aboutUsLzsd = () => {
    return (
      <div className="mAboutAll">
        <CoustTitle title="律政时代" text="Legal Era" />
        <ul className="maboutUsContainer  maboutUsLzsd">
          <li className="m_about_li">
            <div className="maboutText">
              <h3>01</h3>
              <section>
                <h3>我们的理念</h3>
                <p className="maboutTextInfo">
                  专业法律服务不再是遥不可及的高端私人定制，极致的服务意识，彰显出专业的价值
                </p>
              </section>
            </div>
            <div className="aboutSmallImg">
              <img src={mAboutImg} alt="" />
            </div>
          </li>
          <li className="m_about_li">
            <div className="maboutText">
              <h3>02</h3>
              <section>
                <h3>我们的模式</h3>
                <p className="maboutTextInfo">
                  结合工厂流水作业模式、融入保险思维及共享理念打造一站式综合保障性法律服务平台，让昂贵的法律服务惠及千家万户
                </p>
              </section>
            </div>
            <div className="aboutSmallImg">
              <img src={mAboutImg1} alt="" />
            </div>
          </li>
          <li className="m_about_li">
            <div className="maboutText">
              <h3>03</h3>
              <section>
                <h3>我们的前景</h3>
                <p className="maboutTextInfo">
                  中国近1亿中产家庭，9000万中小企业与个体工商户每年有数千亿的法律咨询和服务需求，预计五年内法律
                  服务市场为2000亿
                </p>
              </section>
            </div>
            <div className="aboutSmallImg">
              <img src={mAboutImg2} alt="" />
            </div>
          </li>
        </ul>
      </div>
    );
  };
  // 用你现在的钱,投资未来
  aboutUsAdvertisement = () => {
    return <div className=" maboutUsAdvertisement "></div>;
  };
  // 我们能做什么
  aboutUsDoing = () => {
    let list = [
      {
        title: "私人律师服务",
        text: "低至199元就能拥有7*24小时、一对一为您服务的私人律师, 不限地域无偿代打官司",
        img: mAboutUsImg,
      },
      {
        title: "企业法律顾问",
        text: "自有法律部门提供专业商务谈判、法律培训、重大案情会, 诊风险评估、公司会议等服务",
        img: mAboutUsImg1,
      },
      {
        title: "城市代理服务",
        text: "想创业，钱不够？想投资，却没有好项目？空有人脉资源无法变现？加入我们，你得到的不是一份工作，而是一家公司",
        img: mAboutUsImg2,
      },
    ];
    return (
      <div className="maboutUsDoing_content_all">
        <CoustTitle title="我们能做什么" text="What Hat We Do" />
        <ul className="maboutUsDoing_content ">
          {list.map((item, index) => {
            return (
              <li
                className={"maboutUsDoing_item wow " + item.wowClass}
                key={index}
              >
                <div className="maboutImg">
                  <img src={item.img} alt="" />
                </div>
                <div>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  // 律政时代团队风采
  aboutUsTeam = () => {
    return (
      <div className="maboutUsTeam_left">
        <ul>
          {/* 左侧图片 */}
          <li className="maboutUsTeam_left_img">
            <img src={maboutTeam} alt="" />
            <img src={maboutTeam1} alt="" />
          </li>
          {/* 右侧文字 */}
          <li className="maboutUsTeam_right_text">
            <div>
              <p className="mtext-en">Team Style</p>
              <h3 className="mtext-ch">团队风采</h3>
              <p>加入我们欢乐大家庭</p>
            </div>
            <div>
              <img className="marrow-left" src={maboutArrowL} alt="" />
              <img src={maboutArrowR} alt="" />
            </div>
          </li>
        </ul>
      </div>
    );
  };
  // 咨询超级合伙人项目
  aboutUsPositionform = () => {
    return (
      <div className="maboutPositionAll">
        <div className="maboutUsPositionTitle">
          <div>
            <p className="mpositionTitleLeftEn">
              Shared Lawyer Development Platform
            </p>
            <h4 className="mpositionTitleLeft">一个促进共享律师发展的平台</h4>
          </div>
          <div className="mpositionTitleRight">
            <p className="mpositionTitleLeftEn">律师，梦想开始的地方</p>
            <h4 className="mpositionTitleRight">咨询超级合伙人项目</h4>
          </div>
        </div>
        <section className="maboutUsPositionform">
          <div
            className={
              !this.state.titleInfo
                ? "maboutUsPositionform_title"
                : "maboutUsPositionform_title maboutUstitle_warning"
            }
          >
            <p>{this.state.titleInfo}</p>
          </div>
          <div className="maboutUsPositionform_item">
            <input
              type="text"
              placeholder="请输入您的姓名"
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
            />
          </div>
          <div className="maboutUsPositionform_item">
            <input
              type="number"
              placeholder="请输入您的电话"
              max={11}
              onChange={(e) => {
                this.setState({ mobile: e.target.value });
              }}
            />
          </div>
          <div className="maboutUsPositionform_item">
            <input
              type="text"
              placeholder="请输入您的城市"
              onChange={(e) => {
                this.setState({ city: e.target.value });
              }}
            />
          </div>
          <div className="maboutUsPositionform_item">
            <input
              type="text"
              placeholder="请输入您的备注"
              onChange={(e) => {
                this.setState({ caseCategory: e.target.value });
              }}
            />
          </div>
          <div className="maboutUsPositionform_btn">
            <button
              onClick={() => {
                this.aboutUsBtn();
              }}
            >
              点击提交
            </button>
          </div>
        </section>
      </div>
    );
  };
}

export default MobileAboutUs;
