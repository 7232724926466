// 企业律师服务
import React from "react";
import BaseComponent from "./../../../base";
import "./m_findInfo.css";
import Title from "../../../compontents/title_coust/title_coust";

class MobileFindInfo extends BaseComponent {
  state = {
    info: {},
  };
  componentDidMount() {
    this.changeNav("m_findLawyer");
    this.getInfo();
    this.wowInit();
  }
  getInfo = () => {
    let obj = {};
    if (this.props.location && this.props.location.query) {
      sessionStorage.setItem(
        "lawyerInfo",
        JSON.stringify(this.props.location.query.info)
      );
      obj = this.props.location.query.info;
    } else {
      obj = JSON.parse(sessionStorage.getItem("lawyerInfo"));
    }

    this.setState({
      info: obj,
    });
  };

  render() {
    let { info } = this.state;
    return (
      <div className="mfindLawyerAll">
        {this.findLawyerInfop(info)}
        {this.findLawyerInfoText(info)}
        {this.findLawyerInfoTag(info)}
      </div>
    );
  }

  // 基本信息
  findLawyerInfop = (info) => {
    return (
      <div className="mfindLawyerInfop_content">
        <div className="mfindLawyerInfop_img">
          <div
            style={{ background: `url(${info.certPhoto}) no-repeat center` }}
          ></div>
        </div>
        <div className="BaseInfo">
     
          <Title title="个人信息" />
          <div className="mareaLawyer">
            <p>姓名：<span>{info.name}</span></p>
            <p>持证编号：<span>{info.licenseNo}</span></p>
            <p>所在地区：<span>{info.adress}</span></p>
            <p>律师事务所：<span>{info.lawOffice}</span></p>
            <p>从业年限：<span>{info.workExp}年</span></p>
          </div>
      
      </div>
      </div>
    );
  };

  // 律师简介
  findLawyerInfoText = (info) => {
    return (
      <div className="lvshijianjie">
        <Title title="律师简介" />
        <div className="mfindLawyerInfoText wow fadeInDown">
          <p>{info.selfIntro}</p>
        </div>
      </div>
    );
  };

  // 擅长领域
  findLawyerInfoTag = (info) => {
    return (
      <div className="mfindLawyerInfoTag wow fadeInDown">
        <Title title="擅长领域" />
        <div className="mfindLawyerInfoTag_content">
          {info.skillfulMajors
            ? info.skillfulMajors.map((item, index) => {
                return (
                  <span key={index} className="mfindLawyerInfoTag_item">
                    {item}
                  </span>
                );
              })
            : null}
        </div>
        <button  className="OnlineConsultation">线上咨询</button>
      </div>
    );
  };
}

export default MobileFindInfo;
