import React from "react";
import BaseComponent from "./../../base";
import{Link} from 'react-router-dom'
import "./m_coustomerLawyer.css";
import CoustTitle from "../../compontents/title_coust/title_coust";
import mPoint from '../../assets/companyLawyer/point.png'
class MobileCoustomerLawyer extends BaseComponent {
  state = {
    caseCategory: "",
    city: "",
    mobile: "",
    name: "",
    titleInfo: "",
  };
  componentWillMount() {
    this.changeNav("m_coustomerLawyer");
  }
  componentDidMount() {
    this.wowInit();
  }
  // 提交咨询
  SubmitForm = () => {
    let { caseCategory, city, mobile, name } = this.state;
    let phoneReg=/^[1][3,4,5,6,7,8][0-9]{9}$/
    if (!name) {
      this.isValue("请输入姓名");
      return;
    }
    if (!caseCategory) {
      this.isValue("请输入案件类型");
      return;
    }
    if (!city) {
      this.isValue("请输入城市");
      return;
    }
    if(!phoneReg.test(mobile)){
      this.isValue("电话号码输入不正确，请重新输入");
      return;
    }
    if (mobile.length !== 11) {
      this.isValue("电话号码输入不正确，请重新输入");
      return;
    }

    this.ajax("consult", {
      caseCategory,
      name,
      mobile,
      city,
    }).then((res) => {
      if (res.status) {
        this.isValue("客服将在五分钟内与您联系，请保持电话畅通！");
        this.setState({
          caseCategory: "",
          name: "",
          mobile: "",
          city: "",
        });
      }
    });
  };
  // 判断字段
  isValue = (string) => {
    this.setState({ titleInfo: string });
    setTimeout(() => {
      this.setState({ titleInfo: "" });
    }, 2000);
  };
  render() {
    return (
      <div className="mcustomerLayerAll">
        <div className="bannerImg">
          
          <div className='bannerImgBtn'>
          <Link  to='/m_buy'>家庭详情版</Link>
          </div>
          <img src={mPoint} alt="" />
        </div>
        {this.coustomerLawyerService()}
        {this.companyAdvantage()}
        {this.formMenu()}
      </div>
    );
  }
  //   共享律师私人服务
  coustomerLawyerService() {
    let list = [
      {
        title: "快速",
        text: ["7*24小时、最快2小时回复"],
      },
      {
        title: "专业",
        text: [
          "律师匹配、专业领域、法务中心、",
          "完善服务、担保机制、监察机制",
        ],
      },
      {
        title: "省事",
        text: ["省时、省心、省力、省钱"],
      },
      {
        title: "全面",
        text: ["不限时间、不限地域", "不限专业无偿代打官司"],
      },
    ];
    return (
      <div className="mlawyerServiceContiner">
        {/* title */}
        <div>
          <p className="mlawyerServiceContiner-En">
            Shared Lawyer Private Services
          </p>
          <h3 className="mlawyerServiceContiner-Cn">共享律师私人服务</h3>
        </div>
        <ul className="mlawyerServiceUl wow fadeInDown">
          {list.map((value, index) => {
            return (
              <li key={"mlawyerService" + index}>
                <div className="mlawyerServiceTitle">{value.title}</div>
                {value.text.map((item, i) => {
                  return <p key={"mlawyerServiceItem" + i}>{item}</p>;
                })}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  //  为什么选择律政时代
  companyAdvantage() {
    let list = [
      {
        title: "及时性",
        text: ["线上线下提供多渠道的沟通方式，在第一时间解决用户的燃眉之急"],
      },
      {
        title: "高效性",
        text: [
          "近百名具备专业律师资格和高水准的线上团队，近万名合作律师提供线下服务",
        ],
      },
      {
        title: "新模式",
        text: [
          "结合工厂流水化作业方式，融入保险思维及共享理念，打造的全方位一站式综合保障性法律服务平台",
        ],
      },
    ];
    let info = [
      {
        title: "自有律所",
        text: ["作为全方位一站式综合保障性的法律服务平台，拥有众多专业律师"],
      },
      {
        title: "专业匹配",
        text: ["根据案件类型,不同情况匹配最优律师为你服务"],
      },
      {
        title: "服务保障",
        text: ["全程监管用户评价，律师失职，平台有追责的权力"],
      },
    ];
    let tip = [
      {
        title: "价格优势",
        text: ["低至199元就能有7*24小时全年无休的一对一服务"],
      },
      {
        title: "广博的人脉资源",
        text: [
          "致力于培养全渠道智库团队，注重吸纳有公安、法院、检查院及政府机构从业经历的精英人才",
        ],
      },
      {
        title: "覆盖地域广",
        text: ["覆盖全国31个省、直辖市、自治区，近万名专业法律服务人员"],
      },
    ];
    return (
      <div className="mchooseService">
        <CoustTitle
          title="为什么要选择律政时代？"
          text="Why Choose The Legal Era"
        />
        <ul className="mchooseUlAll mchooseUl">
          {list.map((value, index) => {
            return (
              <li key={"mchoose" + index}>
                <p className="mchooseTitle">{value.title}</p>
                <p className="mchooseText" key={"mchooseitem" + index}>
                  {value.text}
                </p>
              </li>
            );
          })}
        </ul>
        <ul className="mchooseUlAll mchooseUlMiddle">
          {info.map((value, index) => {
            return (
              <li key={"mchoose" + index}>
                <p className="mchooseTitle">{value.title}</p>
                <p className="mchooseText" key={"mchooseitem" + index}>
                  {value.text}
                </p>
              </li>
            );
          })}
        </ul>
        <ul className="mchooseUlAll mchooseUlBottom">
          {tip.map((value, index) => {
            return (
              <li key={"mchoose" + index}>
                <p className="mchooseTitle">{value.title}</p>
                <p className="mchooseText" key={"mchooseitem" + index}>
                  {value.text}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  //  提交表单
  formMenu() {
    return (
      <div className="mlawyerMenuContiner">
        <div className="mlawyerMenuContinerTitle">
          <p className="mlawyerServiceContiner-En">
            Shared Lawyer Private Services
          </p>
          <h3 className="mlawyerServiceContiner-Cn">共享律师私人服务</h3>
        </div>
        <div className="mformContiner">
          <div className="mformBox wow bounceInLeft">
            <div className="mformTip">
                <p className="mformTip1">解决法律纠纷，多种指定服务为您连线律师</p>
            </div>
            <section className="mformTInfo">
              <div className="mformChooseContiner">
                <div>
                  <input
                    type="text"
                    placeholder="请填写真实姓名"
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </div>
                <div>
              
                  <input
                    type="text"
                    placeholder="请选择案件类型"
                    onChange={(e) => {
                      this.setState({ caseCategory: e.target.value });
                    }}
                  />
                </div>
                <div>
                 
                  <input
                    type="text"
                    placeholder="请输入城市名"
                    onChange={(e) => {
                      this.setState({ city: e.target.value });
                    }}
                  />
                </div>
                <div>
                
                  <input
                    type="number"
                    placeholder="请输入正确的电话号码"
                    onChange={(e) => {
                      this.setState({ mobile: e.target.value });
                    }}
                  />
                </div>
              </div>
            </section>
            <p className="tipText"
              >
                {this.state.titleInfo}
              </p>
            <button className="msubmit-btn" onClick={() => this.SubmitForm({})}>
              立即预约
            </button>
            <p className="mformTip2">
                  客服将在5分钟内与您联系,请保持电话通畅
                </p>
                <p className="mformTip3">
                  (注:为了确保我们更好的为您服务,请确保填写内容真实有效)
                </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileCoustomerLawyer;
