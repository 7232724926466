import React ,{Fragment}from "react";
import BaseComponent from "../../base";
import "./footer.css";
import arrow from '../../assets/entry/arrowRight.png'
class Footer extends BaseComponent {
  collaborate() {
    this.props.history.push({
      pathname: "Collaborate",
    });
  }
  render() {
    return (
      <Fragment>
        {this.EntryAdvertising()}
        {this.Footer()}
      </Fragment>
    );
  }
  //公司简介
  EntryAdvertising() {
    return (
      <div className="advContiner">
        <h1>公司简介</h1>
        <p>
          company profile <img src={arrow} alt="" />
        </p>
      </div>
    );
  }
  Footer() {
    let year = new Date().getFullYear();
    return (
      <div className="footerContiner">
        <div className="footerWorkContiner">
          <div className="footTop">
            <ul className="footerUl">
              <li>
                <p>平台保证</p>
                <span>赔付保障</span>
                <span>执业认证</span>
                <span>网站认证</span>
              </li>
              <li>
                <p>律师服务</p>
                <span>私人律师服务</span>
                <span>企业法律顾问</span>
                <span>在线法律咨询</span>
              </li>
              <li>
                <p>关于我们</p>
                <span>公司介绍</span>
                <span>联系我们</span>
                <span>公司地址</span>
              </li>
              <li>
                <p>合作代理</p>
                <span>市级代理</span>
                <span>区县代理</span>
                <span>超级合伙人</span>
              </li>
              <li>
                <p>联系我们</p>
                <span>全国热线电话</span>
                <span>  
                  <i className="iconfont icon-icon-test23"></i>400-777-7091
                </span>
              </li>
              <li>
                <p className="companyCnGoa"> 公民的专属私人律师 </p>
                <p className="companyCnGoa">企业的专属法律顾问</p>
                <span className="companyEnGoal">Exclusive legal services!</span>
              </li>
            </ul>
            {/* <div className="footerBtnContiner">
                  <button onClick={() => {
                                        window.location.href= "http://p.qiao.baidu.com/cps/chat?siteId=13909139&userId=28915778"
                                    }}>在线咨询</button>
                                    <NavLink to="/collaborate">合作代理</NavLink>
                </div> */}
          </div>
          <p className="companyCopy">
            版权所有© {year} 成都市律政时代信息科技有限公司
            任何个人或者单位、团体不得抄袭本网站上的所有内容，含文字、图片等信息，违者必究{" "}
            <a href="https://beian.miit.gov.cn/">署ICP备19013309号-1</a>{" "}
            友情链接申请号：400-777-7091
          </p>
          <p className="companyCopy">
            COPYRIGHT {year} CHENGDU LAWYERS TIMES TECHNOLOGY CO,LTD. ANY
            INDIVIDUAL, UNIT OR GROUP MAY NOT COPY ALL THE CONTENTS OF THIS
            WEBSITE, INCLUDING TEXT, PICTURES AND OTHER INFORMATION.
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
